<template>
  <v-container fluid>
    <PermissionsForm />
  </v-container>
</template>

<script>
import PermissionsForm from '@components/admin/users/PermissionsForm.vue'

export default {
  name: 'UsersPermissions',
  page: {
    title: 'Permessi',
  },
  components: { PermissionsForm },
}
</script>
