<template>
  <div>
    <v-card>
      <div class="d-flex">
        <RolesSelect
          v-model="role_id"
          rules="required"
          :cols="8"
          :sm="8"
          :md="4"
          :lg="3"
          :xl="2"
          dense
        />
        <div class="pt-3">
          <v-btn
            v-if="!role_id && canUser('admin.roles', 'create')"
            color="primary"
            class="py-5 mr-3"
            @click="role_dialog = true"
            >Crea</v-btn
          >
          <v-btn
            v-if="role_id && canUser('admin.roles', 'update')"
            color="primary"
            class="py-5 mr-3"
            @click="role_dialog = true"
            >Modifica</v-btn
          >
        </div>

        <v-dialog
          v-model="role_dialog"
          max-width="75%"
          :retain-focus="false"
          @click:outside="onRoleModalClose"
        >
          <v-card>
            <v-card-title
              >{{ role_id ? 'Modifica' : 'Crea nuovo' }} ruolo</v-card-title
            >
            <v-card-text>
              <RolesForm
                :title="null"
                :edit="!!role_id"
                @submitted="onSubmittedRole"
              />
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="section_dialog"
          max-width="75%"
          :retain-focus="false"
          @click:outside="resetCurrentSection"
        >
          <v-card>
            <v-card-title>Crea Nuova Sezione</v-card-title>
            <v-card-text>
              <SectionsForm :title="null" @submitted="onSubmittedSection" />
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="operation_dialog"
          max-width="75%"
          :retain-focus="false"
          @click:outside="resetCurrentOperation"
        >
          <v-card>
            <v-card-title>Crea Nuova Operazione</v-card-title>
            <v-card-text>
              <OperationsForm :title="null" @submitted="onSubmittedOperation" />
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>

      <v-data-table
        v-if="show"
        :headers="headers"
        :items="sections"
        item-key="label"
        class="elevation-1"
        hide-default-footer
        disable-pagination
        fixed-header
        height="60vh"
        dense
      >
        <template v-slot:item="{ item }">
          <tr class="text-left">
            <th>
              <div class="d-flex align-center">
                <v-simple-checkbox
                  v-ripple
                  color="primary"
                  :indeterminate="!role_id"
                  :value="wholeSectionIsEnabled({ section: item })"
                  @input="(value) => toggleSection({ section: item, value })"
                ></v-simple-checkbox>
                <strong class="ml-3">{{ item.label | capitalize }}</strong>
              </div></th
            >
            <td
              v-for="operation in operations"
              :key="operation.key"
              class="text-center"
            >
              <div class="d-flex justify-center">
                <v-simple-checkbox
                  v-ripple
                  color="primary"
                  :indeterminate="!role_id"
                  :value="isChecked({ section: item, operation })"
                  :disabled="!isCheckboxEnabled({ section: item, operation })"
                  @input="
                    (value) => change({ section: item, operation, value })
                  "
                ></v-simple-checkbox>
              </div>
            </td>
            <td>&nbsp;</td>
          </tr>
        </template>

        <template v-slot:header.add_operation>
          <v-btn
            v-if="canUser('admin.operations', 'create')"
            color="primary"
            text
            small
            @click="operation_dialog = true"
          >
            <v-icon left>mdi-plus</v-icon>
            OP
          </v-btn>
        </template>
        <template v-slot:footer>
          <v-card-text>
            <v-row>
              <v-col>
                <v-btn
                  v-if="canUser('admin.sections', 'create')"
                  color="primary"
                  text
                  small
                  @click="section_dialog = true"
                >
                  <v-icon left>mdi-plus</v-icon>
                  Sezione
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
      </v-data-table>

      <v-divider></v-divider>
      <v-card class="px-5 py-5">
        <v-btn color="primary" :disabled="!canSave" @click="updateRole">
          Salva modifiche
        </v-btn>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import RolesSelect from '@components/admin/roles/RolesSelect.vue'
import RolesForm from '@components/admin/roles/RolesForm.vue'
import SectionsForm from '@components/admin/sections/SectionsForm.vue'
import OperationsForm from '@components/admin/operations/OperationsForm.vue'
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { authComputed } from '@state/helpers.js'
import rules from './permissionRules.json'

const { mapFields: mapOperationsFiels } = createHelpers({
  getterType: 'operations/getFiltersFields',
  mutationType: 'operations/SET_FILTER_FIELDS',
})

const { mapFields: mapRolesField } = createHelpers({
  getterType: 'roles/getCurrent',
  mutationType: 'roles/SET_CURRENT_FIELDS',
})

const { mapFields: mapSectionsField } = createHelpers({
  getterType: 'sections/getCurrent',
  mutationType: 'sections/SET_CURRENT_FIELDS',
})

export default {
  name: 'PermissionsForm',
  components: {
    RolesSelect,
    RolesForm,
    SectionsForm,
    OperationsForm,
  },
  data: () => ({
    show: true,
    role_id: null,
    section_id: null,
    selected: [],
    role_dialog: false,
    section_dialog: false,
    operation_dialog: false,
    has_changed: false,
  }),
  computed: {
    ...authComputed,
    headers: {
      get() {
        return [
          {
            text: 'Nome',
            value: 'label',
            width: '200px',
            fixed: true,
          },
          ...this.operations.map((op) => {
            return {
              text: op.label,
              value: op.key,
              sortable: false,
              align: 'center',
            }
          }),
          {
            text: '',
            value: 'add_operation',
            sortable: false,
          },
        ]
      },
      set() {},
    },
    // Sections
    ...mapState('sections', {
      sections: 'list',
    }),
    ...mapGetters('sections', ['isOperationPossible']),

    ...mapOperationsFiels(['section']),

    // Sections
    ...mapState('operations', {
      operations: 'list',
    }),
    ...mapSectionsField({
      created_section_key: 'key',
    }),

    // Roles
    ...mapState('roles', {
      roles: 'list',
    }),
    ...mapGetters('roles', {
      canRole: 'canRole',
    }),

    canSave() {
      let answer = false
      if (this.canUser('admin.permissions', 'update')) {
        if (this.role_id) {
          if (this.has_changed) answer = true
        }
      }
      return answer
    },

    ...mapRolesField({
      permissions: 'permissions',
      label: 'label',
      created_role_id: 'id',
    }),
  },
  watch: {
    async role_id(val) {
      if (val) {
        await this.setRole(val)
      } else {
        this.unsetRole()
      }
      this.has_changed = false
    },
  },
  async mounted() {
    await this.getOperations()
    await this.getSections()
  },
  beforeDestroy() {
    this.unsetRole()
    this.resetCurrentOperation()
    this.resetCurrentSection()
  },
  methods: {
    ...mapActions('roles', {
      setRole: 'setCurrent',
      updateRole: 'update',
      getRoles: 'getDropdownList',
    }),
    ...mapMutations('roles', {
      unsetRole: 'RESET_CURRENT',
      setPermissions: 'SET_PERMISSIONS',
    }),
    ...mapActions('sections', {
      getSections: 'getDropdownList',
    }),
    ...mapMutations('sections', {
      resetCurrentSection: 'RESET_CURRENT',
    }),
    ...mapActions('operations', {
      getOperations: 'getDropdownList',
    }),
    ...mapMutations('operations', {
      setOperationsScopes: 'SET_SCOPES',
      resetCurrentOperation: 'RESET_CURRENT',
    }),

    /** For a checkbox to be ticked the operation must be possible,
     *  and the role should be allowed to perform the oepration
     */
    isChecked({ section, operation, role }) {
      let answer = this.isOperationPossible(section.key, operation.key)
      if (this.role_id) answer = answer && this.canRole({ section, operation })
      return answer
    },

    /** For a section checkbox to be enabled all operations
     *  pertaining to the sections must be allowed
     */
    wholeSectionIsEnabled({ section }) {
      let isEnabled = true
      for (const operation of this.operations) {
        // Ignore operations that are not possible on this section
        if (this.isOperationEnabled({ section, operation })) {
          isEnabled = isEnabled && this.canRole({ section, operation })
        }
      }
      return isEnabled
    },

    alert() {
      this.$dialog.notify.error(
        `Non si dispongono delle autorizzazioni necessarie`,
        {
          timeout: 2000,
        }
      )
    },

    /** Check if there's a rule for the whole section or the single operation */
    theresARule({ section, operation, value }) {
      if (!value) {
        if (rules[section.key]) {
          if (rules[section.key].all) return true
          if (rules[section.key][operation.key]) return true
        }
        return false
      }
    },

    change({ section, operation, value }) {
      if (!this.role_id) return
      /* All the sections and operations that are present in rules cannot be deactivated */
      if (this.theresARule({ section, operation, value })) {
        this.alert()
        return
      }
      this.show = false

      this.setPermissions({
        section: section.key,
        operation: operation.key,
        value,
      })
      // Workaround force refresh
      this.has_changed = true
      this.headers = [...this.headers]
      this.$nextTick(() => (this.show = true))
    },

    /** Enables or disables the whole section */
    toggleSection({ section, value }) {
      if (this.theresARule({ section, operation: false, value })) {
        this.alert()
        return
      }
      for (const operation of this.operations) {
        // Ignore operations that are not possible on this section
        if (this.isOperationEnabled({ section, operation })) {
          this.change({ section, operation, value })
        }
      }
    },

    /**
     * This gathers the flow of decisions that allow a checkbox to be enabled or disabled
     */
    isCheckboxEnabled({ section, operation }) {
      return this.isOperationEnabled({ section, operation })
    },

    /** If an operation is bound to a section, check if it is the current section */
    isOperationEnabled({ section, operation }) {
      let enabled = true
      if (operation.section) enabled = operation.section === section.key
      return enabled
    },

    async onSubmittedRole() {
      await this.getRoles({})
      this.role_id = this.created_role_id
      this.role_dialog = false
    },
    onRoleModalClose() {
      this.unsetRole()
      this.role_id = null
    },
    async onSubmittedSection() {
      await this.getSections()
      this.resetCurrentSection()
      this.section_dialog = false
    },
    onSubmittedOperation() {
      this.getOperations()
      this.resetCurrentOperation()
      this.operation_dialog = false
    },
  },
}
</script>

<style lang="scss">
.v-data-table {
  strong {
    white-space: nowrap;
  }
}
</style>
