<template>
  <FormItem
    v-model="id"
    type="select"
    :values="list"
    name="Sezione"
    :rules="rules"
    :cols="cols"
    :sm="sm"
    :md="md"
    :lg="lg"
    :xl="xl"
    :dense="dense"
  />
</template>

<script>
import FormItem from '@components/forms/FormItem.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'SectionsSelect',
  components: {
    FormItem,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    cols: {
      type: Number,
      default: 6,
    },
    sm: {
      type: Number,
      default: null,
    },
    md: {
      type: Number,
      default: 3,
    },
    lg: {
      type: Number,
      default: null,
    },
    xl: {
      type: Number,
      default: null,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Object],
      default: '',
    },
  },
  computed: {
    ...mapState('sections', {
      list: (state) =>
        state.list.map((el) => ({ text: el.label, value: el.key })),
    }),
    id: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      },
    },
  },
  methods: {
    ...mapActions('sections', ['getDropdownList']),
  },
}
</script>
