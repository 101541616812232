<template>
  <Form :title="title" :loading="loading" @submit="onSubmit" @reset="onReset">
    <h4>Dati</h4>
    <v-row>
      <FormItem
        v-model="label"
        name="label"
        label="Nome Sezione"
        rules="required"
        :cols="12"
        :sm="12"
        :md="6"
        :lg="6"
        :xl="6"
        @input="setKeyFromLabel"
      />

      <FormItem
        v-model="key"
        label="Chiave"
        name="key"
        :cols="12"
        :sm="12"
        :md="6"
        :lg="6"
        :xl="6"
      />
    </v-row>
  </Form>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapMutations, mapState } from 'vuex'

import Form from '@components/forms/Form.vue'
import FormItem from '@components/forms/FormItem.vue'

const { mapFields } = createHelpers({
  getterType: 'sections/getCurrent',
  mutationType: 'sections/SET_CURRENT_FIELDS',
})

export default {
  name: 'SectionsForm',
  components: {
    FormItem,
    Form,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Crea Nuovo',
    },
  },
  data: () => ({
    statuses: [],
  }),
  computed: {
    ...mapFields(['key', 'label']),
    ...mapState('sections', {
      id: (state) => state.current.id,
      loading: (state) => state.loading,
    }),
  },
  methods: {
    ...mapMutations('sections', {
      reset: 'RESET_CURRENT',
      setCurrentValues: 'SET_CURRENT',
      setKeyFromLabel: 'SET_KEY_FROM_LABEL',
    }),
    ...mapActions('sections', ['create', 'update']),
    async onSubmit() {
      this.$emit('submit')

      if (!this.edit) {
        await this.create()
      } else {
        await this.update()
      }

      this.$emit('submitted', this.id)
    },
    onReset() {
      this.reset()
      this.$emit('resetted')
    },
  },
}
</script>

<style scoped></style>
